var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-1"},[(_vm.Matter)?_c('div',{staticClass:"matterCard",class:{
        'matter-card-max-width': _vm.isAnual && _vm.Matter.regime != _vm.isAnual.id,
      }},[_c('div',{staticClass:"leftCol"},[_c('div',{staticClass:"leftLabel",attrs:{"id":`numTabBtn-${_vm.Matter.id}`}},[_vm._v(" "+_vm._s(_vm.Matter.order)+" ")]),_c('div',{staticClass:"leftLabel",attrs:{"id":`hoursTabBtn-${_vm.Matter.id}`}},[_vm._v(" "+_vm._s(_vm.semesterClassroonHours())+" ")]),(
            _vm.Matter &&
            _vm.profileType &&
            _vm.institution &&
            _vm.institution.internal_use_id != 'ciisa_uss'
          )?_c('div',{staticClass:"leftLabel",attrs:{"id":`creditsTabBtn-${_vm.Matter.id}`}},[(_vm.Matter.use_matter_values && _vm.oldMatter)?_c('div',[_vm._v(" "+_vm._s(_vm.oldMatter.credits)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.Matter.credits)+" ")])]):_vm._e(),(
            (_vm.Matter && _vm.Matter.modalities) ||
            (_vm.Matter.use_matter_values && _vm.oldMatter && _vm.oldMatter.modalities)
          )?_c('div',{staticClass:"leftLabel text-break",attrs:{"id":`modalities-badge-${_vm.Matter.id}`},on:{"click":(e) => e.target.classList.toggle('text-break')}},[_vm._v(" "+_vm._s(_vm.modalitiesByOrder)+" "),(_vm.Matter.use_matter_values)?[(_vm.oldMatter)?_c('ModalitiesPopover',{attrs:{"object":_vm.Matter,"content":_vm.matterModalities(_vm.oldMatter.modalities),"triggers":'hover',"placement":'left'}}):_vm._e()]:[_c('ModalitiesPopover',{attrs:{"object":_vm.Matter,"content":_vm.matterModalities(_vm.Matter.modalities),"triggers":'hover',"placement":'left'}})]],2):_vm._e(),_c('b-popover',{attrs:{"target":`numTabBtn-${_vm.Matter.id}`,"placement":"left","triggers":"hover focus","content":`N° de orden de la ${_vm.$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}`}}),[_c('b-popover',{attrs:{"target":`hoursTabBtn-${_vm.Matter.id}`,"placement":"left","triggers":"hover focus","content":_vm.$getVisibleNames(
                'manual.total_pedagogical_hours',
                true,
                'Total de Horas Pedagógicas'
              )}})],(_vm.institution && _vm.institution.internal_use_id != 'ciisa_uss')?_c('b-popover',{attrs:{"target":`creditsTabBtn-${_vm.Matter.id}`,"placement":"left","triggers":"hover focus","content":`${_vm.$getVisibleNames(
            'manual.credits',
            true,
            'Créditos SCT'
          )}`}}):_vm._e(),(
            _vm.Matter &&
            _vm.profileType &&
            _vm.institution &&
            _vm.institution.internal_use_id == 'duoc_uc'
          )?_c('b-popover',{attrs:{"target":`institutionalCreditsTabBtn-${_vm.Matter.id}`,"placement":"right","triggers":"hover focus","content":`${_vm.$getVisibleNames(
            'manual.institutional_credits',
            true,
            'Créditos Institucionales'
          )}`}}):_vm._e()],2),_c('div',{staticClass:"matterTitle",style:({
          'background-color': _vm.matterFormationLine,
        }),attrs:{"title":_vm.getMatterName(_vm.Matter.matter)}},[_c('div',{staticClass:"matterLink"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `${_vm.$getVisibleNames('manual.matter_code', false, 'Código')}`
            ),expression:"\n              `${$getVisibleNames('manual.matter_code', false, 'Código')}`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"code-matter mb-1",attrs:{"variant":"none","pill":""}},[_vm._v(_vm._s(_vm.Matter.code))]),(
              (_vm.user && ![6, 7].includes(_vm.user.groups[0])) ||
              (_vm.user &&
                [6, 7].includes(_vm.user.groups[0]) &&
                _vm.allow_redirecting_matter)
            )?_c('div',[_c('b-link',{attrs:{"id":`matter-link-${_vm.Matter.id}`,"to":`/matter/form/${_vm.Matter.id}/`,"title":""}},[_c('div',{staticClass:"word-break"},[_vm._v(" "+_vm._s(_vm.getMatterName(_vm.Matter.matter).toUpperCase())+" ")])])],1):_c('div',[_vm._v(" "+_vm._s(_vm.getMatterName(_vm.Matter.matter).toUpperCase())+" ")]),(
              (_vm.user && ![6, 7].includes(_vm.user.groups[0])) ||
              (_vm.user &&
                [6, 7].includes(_vm.user.groups[0]) &&
                _vm.allow_redirecting_matter)
            )?_c('b-tooltip',{attrs:{"target":`matter-link-${_vm.Matter.id}`,"variant":"secondary","placement":"right","boundary-padding":100,"noninteractive":""}},[_c('b-icon',{attrs:{"icon":"box-arrow-up-right"}}),_vm._v(" Ir al "+_vm._s(_vm.$getVisibleNames( "manual.matter_program", false, "Programa de Asignatura" ))+" ")],1):_vm._e()],1)]),_c('div',{staticClass:"rightCol"},[_c('div',{staticClass:"preqLabel text-break",attrs:{"id":`prerequisite-label-${_vm.Matter.id}`},on:{"click":(e) => e.target.classList.toggle('text-break')}},[(_vm.prerequisitesIds.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.prerequisitesIds)+" ")]):_vm._e(),(_vm.prerequisitesIds.length == 0)?_c('span',[_vm._v("-")]):_vm._e(),_c('PrerequisitesPopover',{attrs:{"object":_vm.Matter,"content":_vm.prerequisitesFiltered,"placement":'right',"triggers":'hover focus',"title":'Prerrequisitos'}})],1),[_c('div',{staticClass:"compLabel",attrs:{"id":`popover-button-variant-${_vm.Matter.id}`}},[(_vm.competencesOrderedList.length > 0)?[_c('div',{staticClass:"text-break",on:{"click":(e) => e.target.classList.toggle('text-break')}},[_vm._v(" "+_vm._s(_vm.competencesOrderedList)+" ")])]:[_c('div',[_vm._v("-")])],_c('b-popover',{attrs:{"target":`popover-button-variant-${_vm.Matter.id}`,"triggers":"hover","placement":"righttop"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.competence", true, "Competencias"))+" ")]},proxy:true}],null,false,3257285388)},[(_vm.competencesList.length)?_vm._l((_vm.competencesList),function(competence){return _c('div',{key:competence.id},[_c('div',{key:competence.id,staticClass:"competence-container"},[_c('SentenceContainer',{attrs:{"Sentence":competence,"boundedTextContainer":true},scopedSlots:_vm._u([{key:"order",fn:function(){return [_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(competence.profile_cycle ? competence.profile_cycle.order + "." + competence.order : competence.order + ".")),(
                              competence.sub_order > 0 &&
                              _vm.institution &&
                              _vm.institution.show_competence_sub_order
                            )?_c('span',[_vm._v("."+_vm._s(competence.sub_order))]):_vm._e()])]},proxy:true}],null,true)})],1)])}):[_c('div',[_vm._v(" Esta "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ))+" no tiene ninguna "+_vm._s(_vm.$getVisibleNames("mesh.competence", false, "Competencia"))+" asociada. ")])]],2)],2)],(
            _vm.Matter &&
            _vm.profileType &&
            _vm.institution &&
            _vm.institution.internal_use_id == 'duoc_uc'
          )?_c('div',{staticClass:"institutionalCreditLabel",attrs:{"id":`institutionalCreditsTabBtn-${_vm.Matter.id}`}},[(_vm.Matter.use_matter_values && _vm.oldMatter)?_c('div',[_vm._v(" "+_vm._s(_vm.oldMatter.institutional_credits)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.Matter.institutional_credits)+" ")])]):_vm._e()],2)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }